.btn {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none;
  }
  &--dropdown {
    padding: 0;
    border: 0;
    font: var(--size-base) var(--roboto-medium);
    background: none;
    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none;
    }
    .dropdown-icon {
      margin-left: 20px;
      color: var(--border);
      background: url(./images/logout_dropdown_icon.png) no-repeat;
      width: 15px;
      height: 10px;
      display: inline-block;
    }
  }
  &--done {
    border: 1px solid var(--white);
    background: transparent;
    border-radius: 0;
    padding: 5px 15px;
    font: var(--size-base) var(--roboto-regular);

    &:focus,
    &:active,
    &:hover {
      color: var(--white);
    }
  }
  &--ok {
    border: 1px solid var(--white);
    background: transparent;
    border-radius: 0;
    padding: 5px 18px;
    color: var(--white);
    &:focus,
    &:active,
    &:hover {
      color: var(--white);
    }
  }
  &--back {
    border: 1px solid var(--completed);
    background: var(--completed);
    border-radius: 0;
    padding: 5px 25px;
    color: var(--white);
    font: var(--size-l) var(--roboto-regular);
    &:focus,
    &:active,
    &:hover {
      color: var(--white);
    }
  }
  &--dearchive {
    border: 1px solid #379fd5;
    background: #379fd5;
    border-radius: 0;
    padding: 5px 25px;
    color: var(--white);
    font: var(--size-l) var(--roboto-regular);
    &:focus,
    &:active,
    &:hover {
      color: var(--white);
    }
  }
  &--save-tag {
    border: 1px solid #1382c5;
    background: #1382c5;
    border-radius: 8px;
    padding: 5px 25px;
    color: var(--white);
    font: var(--size-l) var(--roboto-regular);
    &:focus,
    &:active,
    &:hover {
      color: var(--white);
    }
  }
  &--cancel {
    border: 0;
    color: #379fd5;
    background: transparent;
    font: var(--size-l) var(--roboto-regular);
    &:focus,
    &:active,
    &:hover {
      color: #379fd5;
    }
  }
  &--cancel2 {
    border: 0;
    color: #323f51;
    background: transparent;
    font: var(--size-l) var(--roboto-regular);
    &:focus,
    &:active,
    &:hover {
      color: #323f51;
    }
  }
  &--cancel3 {
    border: 0;
    color: #a6aeb6;
    min-height: 40px;
    min-width: 156px;
    background: transparent;
    font: var(--size-l) var(--roboto-regular);
    &:focus,
    &:active,
    &:hover {
      color: #a6aeb6;
    }
  }
  &--cancelBulk {
    border: 0;
    color: var(--white);
    background: transparent;
    font: var(--size-base) var(--roboto-regular);
    &:focus,
    &:active,
    &:hover {
      color: var(--white);
    }
  }
  &--print {
    background-color: #323f51;
    padding: 10px 15px !important;
    color: var(--white);
    font: var(--size-base) var(--roboto-regular);
    border: 1px solid #323f51;
    border-radius: 8px;
    margin-bottom: 14px !important;
    &:focus,
    &:active,
    &:hover {
      color: var(--white);
    }
  }
  &--template {
    background-color: #4cc89f;
    border: 1px solid #4cc89f;
    border-radius: 3px;
    margin-bottom: 14px;
    color: var(--white);
    font: var(--size-s) var(--roboto-regular);
    width: 150px;
    min-height: 35px;
    &:focus,
    &:active,
    &:hover {
      color: var(--white);
    }
  }
  &--template2 {
    background-color: #4cc89f;
    border: 1px solid #4cc89f;
    border-radius: 3px;
    margin-bottom: 14px;
    color: var(--white);
    font: var(--size-s) var(--roboto-regular);
    min-width: 150px;
    min-height: 35px;
    &:focus,
    &:active,
    &:hover {
      color: var(--white);
    }
  }
  &--copy {
    background-color: transparent;
    border: 1px solid #233b4f;
    border-radius: 3px;
    margin-bottom: 14px;
    color: #233b4f;
    font: var(--size-s) var(--roboto-regular);
    width: 150px;
    min-height: 35px;
    &:focus,
    &:active,
    &:hover {
      color: #233b4f;
    }
  }
  &--archive {
    background-color: var(--ed-medium_grey);
    border: 1px solid var(--ed-medium_grey);
    border-radius: 8px;
    margin-bottom: 14px;
    color: var(--ed-dark_blue);
    font: var(--size-s) var(--roboto-regular);
    min-height: 45px;
    min-width: 156px;
    margin-right: 15px !important;
    box-shadow: 0 2px 4px 0 rgba(112, 112, 112, 0.2);
    &:focus,
    &:active,
    &:hover {
      color: var(--ed-dark_blue);
    }
  }
  &--agree {
    background-color: #337ab7;
    border: 1px solid #2e6da4;
    border-radius: 3px;
    margin-bottom: 14px;
    color: var(--white);
    font: var(--size-s) var(--roboto-regular);
    width: 100%;
    min-height: 35px;
    &:focus,
    &:active,
    &:hover {
      background-color: #286090;
      border-color: #204d74;
      color: var(--white) !important;
    }
  }
  &--save {
    background-color: var(--ed-blue);
    border: 1px solid var(--ed-blue);
    border-radius: 8px;
    margin-bottom: 0;
    color: var(--white);
    font: var(--size-base) var(--roboto-regular);
    width: 100%;
    min-height: 53px;
    min-width: 156px;
    &:focus,
    &:active,
    &:hover {
      background-color: var(--ed-blue);
      border-color: var(--ed-blue);
      color: var(--white) !important;
    }
  }
  &--save2 {
    background-color: var(--ed-blue);
    border: 1px solid var(--ed-blue);
    border-radius: 4px;
    margin-bottom: 0;
    color: var(--white);
    font: var(--size-base) var(--roboto-regular);

    min-height: 40px;
    min-width: 156px;
    &:focus,
    &:active,
    &:hover {
      background-color: var(--ed-blue);
      border-color: var(--ed-blue);
      color: var(--white) !important;
    }
  }
  &--save3 {
    background-color: transparent;
    border: 1px solid var(--ed-blue);
    border-radius: 8px;
    margin-bottom: 0;
    color: #1382c5;
    font: var(--size-base) var(--roboto-regular);

    min-height: 40px;
    min-width: 156px;
    &:focus,
    &:active,
    &:hover {
      background-color: transparent;
      border-color: var(--ed-blue);
      color: #1382c5 !important;
    }
  }
  &--save4 {
    background-color: transparent;
    border: 1px solid var(--ed-red);
    border-radius: 4px;
    margin-bottom: 0;
    color: var(--ed-red);
    font: var(--size-base) var(--roboto-regular);

    min-height: 40px;
    min-width: 156px;
    &:focus,
    &:active,
    &:hover {
      background-color: transparent;
      border-color: var(--ed-red);
      color: var(--ed-red) !important;
    }
  }
  &--save5 {
    background-color: var(--ed-red);
    border: 1px solid var(--ed-red);
    border-radius: 4px;
    margin-bottom: 0;
    color: var(--white);
    font: var(--size-base) var(--roboto-regular);

    min-height: 40px;
    min-width: 106px;
    &:focus,
    &:active,
    &:hover {
      background-color: var(--ed-red);
      border-color: var(--ed-red);
      color: var(--white) !important;
    }
    &[disabled] {
      border-color: #cecece;
      background-color: #cecece;
    }
  }
}

.select-style {
  border: 1px solid #ccc;
  border-radius: 0;
  overflow: hidden;
  background: var(--white) url(./images/down-arrow.svg) no-repeat 98% 50%;
  background-size: 5%;

  select {
    height: 25px;
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.visible-hidden {
  visibility: hidden !important;
}
.MuiCheckbox-root {
  color: var(--ed-light_grey) !important;
  &.Mui-checked {
    color: var(--ed-blue) !important;
  }
}

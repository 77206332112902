@import './variables';
/*Show more left menu*/
.show-more {
  cursor: pointer;
  font: var(--size-xs) var(--roboto-regular);
  color: var(--ed-blue);
  margin: 10px 0 10px 30px;
  display: block;
  &:hover {
    opacity: 0.5;
  }
  img {
    transition: 0.5s linear;
    width: 12px;
    height: 12px;
    margin-left: 5px;
  }
  &.show-less {
    margin-bottom: 50px;
    img {
      transition: 0.5s linear;
      transform: rotate(180deg);
    }
  }
}
.person-filter-container {
  .responsible {
    background: url('/images/RESPONSIBLE-small.svg') no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
  }
  .CREATEDBY {
    background: url('/images/CREATOR (2).svg') no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
  }
  .consulted {
    background: url('/images/CONSULTED-small.svg') no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
  }
  .informed {
    background: url('/images/INFORMED-small.svg') no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
  }
  .LASTMODIFIEDBY {
    background: url('/images/CREATED-small.svg') no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
  }
}

/*Checkbox*/
.checkbox-container {
  position: relative;
  margin-right: 30px;
  cursor: pointer;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      & ~ .checkmark {
        &:after {
          display: block;
        }
      }
    }
  }
  .checkmark {
    &:after {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid var(--black);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid var(--ed-light_grey);
  border-radius: 2px;
  background: var(--white);
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
  &:focus {
    outline: none !important;
    border: none !important;
  }
}
/*Bulk action statu bar*/
.bulk-action {
  top: 119px;
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  align-items: center;
  background: #f7f8f9;
  margin: 15px 0 10px -20px;
  padding: 10px 20px;
  z-index: 10;
  border: 1px solid #8a9cad12;

  &.filter-chip-visible {
    top: 150px;
  }

  .print {
    &__delete {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .display-selected-tickets {
    align-items: center;
    color: var(--ed-blue);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font: var(--size-s) var(--roboto-regular);
    height: 31px;
    padding: 10px;
    margin-right: 20px;
  }
  .display-selected-tickets-value {
    margin-right: 5px;
  }
  .checkmark {
    top: -7px;
  }
  .display-selected-tickets-close {
    background-image: url(/images/close.svg);
    background-repeat: no-repeat;
    width: 17px;
    border: 1px solid var(--white);
    border-radius: 8px;
    padding: 7px;
    margin-left: 10px;
    background-color: var(--white);
    cursor: pointer;
    background-position: center;
  }
  &--arrow {
    height: 12px;
    margin-left: 15px;
  }
  .export-bulk-action-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../src/images/export-new-small-icon.svg);
    height: 30px;
    width: 30px;
    cursor: pointer;

    &:hover {
      background-color: #1382c526;
      border-radius: 50%;
    }
  }
  &__action {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .bulk-icons {
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }

    &--archieve {
      @extend .bulk-icons;
      background: url(/images/ARCHIVE_icon.svg);
      margin-left: 10px;
      cursor: pointer;
      &:hover {
        background: url(/images/ARCHIVE_icon.svg);
        background-color: #1382c526;
      }
      &.dearchive_icon {
        background: url(/images/de_archive.svg);
      }
    }

    &--tags {
      position: relative;
      &--icon {
        @extend .bulk-icons;
        background: url(/images/tag-icon.svg);
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          background: url(/images/tag-icon.svg);
          background-color: #1382c526;
          background-repeat: no-repeat;
          background-position: center;
        }
        &.editing {
          background: url(/images/tag-icon-selected.svg);
          background-color: #2c3d50;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .arrow {
        left: 15px !important;
        z-index: -1;
      }
    }
    &--responsible {
      margin-left: 12px;
      position: relative;

      &--icon {
        background: url(/images/action-person.svg);
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        background-size: 30px;
        cursor: pointer;

        &.editing {
          background: url(/images/action-person_white.svg);
          background-size: 30px;
          background-color: #2c3d50;

          &:hover {
            background: url(/images/action-person_white.svg);
            background-color: #1382c526;
            background-size: 30px;
          }
        }

        &:hover {
          background: url(/images/action-person.svg);
          background-color: #1382c526;
          background-size: 30px;
        }
      }

      &--email {
        background-color: #2c3d50;
        padding: 10px 15px;
        position: absolute;
        top: 45px;
        min-height: 130px;
        width: 380px;
        z-index: 10;
        color: var(--white);

        & .arrow {
          width: 20px;
          height: 20px;
          position: absolute;
          background-color: #2c3d50;
          transform: rotate(135deg);
          top: -5px;
          left: 5px;
        }

        & p {
          margin: 0 0 5px 5px;
          & span {
            color: var(--ed-blue);
            text-transform: lowercase;
          }
        }

        & .select-imposter {
          position: relative;

          & .role-selector {
            background-color: var(--white);
            margin-bottom: 20px;
            cursor: pointer;
            background-image: url(/images/logout_dropdown_icon.png);
            background-repeat: no-repeat;
            background-position: 95% center;
            &.Mui-disabled {
              background-image: none;
            }

            & .MuiSelect-select {
              color: #868889b5;
              font-size: 12px;
              padding-left: 10px;
              padding-top: 8px;
            }

            & svg {
              display: none;
            }
          }
          .remove-tag {
            margin-top: 10px;
          }
          .email-input {
            #filter-responsible {
              background-image: none !important;
              padding: 3px 0;
              font-size: 12px;
            }
            .tag-warn {
              margin-top: 10px;
              margin-left: 0;
            }
            width: 100%;

            &.tags-avail,
            &.person {
              .MuiAutocomplete-tag {
                font-size: var(--size-xs);
              }
              .MuiInputBase-input {
                &::-webkit-input-placeholder {
                  color: var(--ed-dark_blue) !important;
                  opacity: 1 !important;
                }
                &:-ms-input-placeholder {
                  color: var(--ed-dark_blue) !important;
                  opacity: 1 !important;
                }
                &::placeholder {
                  color: var(--ed-dark_blue) !important;
                  opacity: 1 !important;
                }
              }
              .MuiChip-root {
                background: transparent !important;
                &:last-of-type::after {
                  content: unset;
                }
                &::after {
                  content: ',';
                  color: #2c3d50 !important;
                }
                & .MuiChip-deleteIcon {
                  margin-left: 1px;
                  color: #2c3d50 !important;
                  display: none;
                }
              }
              .MuiInputLabel-filled {
                display: none !important;
              }
              .MuiFilledInput-adornedEnd {
                padding: 0;
                background: var(--white) !important;
                border-radius: 4px;
                padding-left: 10px;
              }
              .autocomplete-input input,
              .MuiChip-label {
                color: #2c3d50 !important;
                border: none !important;
                padding: 0;
                height: 38px;
                line-height: 38px;
                font-size: var(--size-xs);
              }
            }
            &.person {
              & .MuiChip-root .MuiChip-deleteIcon {
                display: none;
              }
            }
          }
        }

        & button {
          background-color: #2c3d50;
          border-radius: 0;
          color: white;
          margin-top: 20px;
          float: right;
          border: none;
          padding: 6px 10px;

          &:first-of-type {
            border: 1px solid white;
          }
        }
        .MuiRadio-root {
          color: var(--ed-light_grey) !important;
          &.Mui-checked {
            color: var(--white) !important;
          }
        }
      }
    }

    &--status {
      position: relative;
      &--icon {
        @extend .bulk-icons;
        margin-left: 10px;
        cursor: pointer;
        padding: 7.5px 0 0 7.5px;
        background: url(/images/status-change.svg);

        &:hover {
          background: url(/images/status-change-hover.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-color: #1382c526;
        }
        &.editing {
          background: url(/images/status-change-active.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-color: #2c3d50;
        }
      }
      .arrow {
        left: 15px !important;
        z-index: -1;
      }
      .radio-click > div {
        display: flex;
        align-items: center;
        .completed-bgicon,
        .started-bgicon {
          margin: 0 5px;
        }
      }
    }
  }
}

/*Date Filter*/
.dueDate-wrapper {
  position: relative;
  width: fit-content;
  &:hover > .remove-date-btn {
    display: block;
  }
  .remove-date-btn {
    display: none;
    position: absolute;
    left: 115px !important;
    top: 4px;
    opacity: 1;
    border: none;
    border-radius: 50%;
    background: transparent;
    transition: 0.2s ease-in;
    &:hover {
      display: block;
      background: #f0f3f8;
    }
    .MuiSvgIcon-root.remove-icon {
      margin-top: 5px;
    }
  }
}
.close-icon {
  position: relative;
  left: -25px;
  top: 0px;
  fill: #cecece !important;
  cursor: pointer;
}
/*Add user*/
.add-user {
  @media screen and (max-width: 1024px) {
    width: 880px;
  }

  & .MuiDialog-paperScrollPaper {
    width: calc(100vw - 70%);
    border-radius: 0;
  }

  &__header {
    background-color: #48637a;
    padding: 25px;
    color: var(--white);
    display: flex;
    align-items: center;

    .name-container {
      font: var(--size-xl) var(--roboto-regular);
      color: var(--white);
      margin: 0;
      display: -webkit-box;
      max-width: 610px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .add-user-close {
      background: url(/images/close-blue.png) no-repeat;
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      right: 20px;
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0px 20px;

    .license-check {
      background-color: #ffe8e8;
      margin-top: 20px;
      border-radius: 8px;
      padding: 15px;
      color: #233b4f;

      img {
        position: absolute;
      }

      .license-info {
        margin-left: 15px;
      }
    }

    .license-type {
      @extend .license-check;
      margin-top: 0;
      margin-bottom: 10px;
      margin-left: 15px;
      .license-info {
        width: 98%;
        margin-left: 15px;
      }
    }
    .license-info {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 20px;
      font: var(--size-s) var(--roboto-regular);
    }
    .red-info {
      flex-basis: 5%;
    }

    .contact-info {
      font: var(--size-base) var(--roboto-regular);
      color: #233b4f;
      margin-left: 1px;
      margin-top: 35px;
    }

    .add-user-input {
      position: relative;
      margin: 40px 5px 0px 5px;
    }

    .user-info {
      margin-top: 40px;
      color: #233b4f;
      font: var(--size-base) var(--roboto-regular);

      img {
        margin-left: 5px;
        margin-bottom: 3px;
      }
    }
    .add-user-role {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
    }

    .support-info,
    .consulted-info,
    .informed-info {
      font: var(--size-s) var(--roboto-regular);
      color: #233b4f;
      margin-left: 25px;
      line-height: 1.5;
      font-style: italic;
    }

    .checkbox-container {
      position: relative;
      margin-right: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked {
          & ~ .checkmark {
            &:after {
              display: block;
            }
          }
        }
      }
      label {
        max-width: 100%;
        margin-bottom: 5px;
        margin-left: 25px;
        color: #233b4f;
        font: var(--size-s) var(--roboto-regular);
        font-weight: 700;
      }

      & .role-name {
        margin-bottom: 15px;
        margin-left: 25px;
        color: #233b4f;
        font: var(--size-s) var(--roboto-regular);
      }
      & .role-name-bold {
        margin-bottom: 15px;
        margin-left: 25px;
        color: #233b4f;
        font: var(--size-s) var(--roboto-regular);
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        border: 1px solid var(--ed-light_grey);
        border-radius: 2px;
        background: var(--white);
        &:after {
          left: 5px;
          top: 1px;
          width: 5px;
          height: 10px;
          border: solid #233b4f;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 35px;
      margin-bottom: 35px;
      .cancel-btn {
        border: 0;
        color: #323f51;
        background: transparent;
        font: var(--size-s) var(--roboto-regular);
      }
      .home-btn {
        background: #2c3d50;
        border: 1px solid #2c3d50;
        border-radius: 4px;
        padding: 10px 28px;
        color: var(--white);
        font: var(--size-s) var(--roboto-regular);
        margin-left: 10px;
      }
      .add-btn {
        border: 1px solid #4ac69f;
        background: #4ac69f;
        border-radius: 4px;
        padding: 10px 28px;
        color: var(--white);
        font: var(--size-s) var(--roboto-regular);
        margin-left: 10px;

        &:disabled {
          background: var(--ed-light_grey);
          border: var(--ed-light_grey);
          border-radius: 4px;
        }
      }
    }

    .MuiButton-root {
      color: #233b4f;
      padding: 6px 5px;
      font: var(--size-base) var(--roboto-regular);
      min-width: 64px;
      box-sizing: border-box;
      text-transform: none;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
/*Common custom dialog*/
.print-custom-dialog {
  font: var(--size-s) var(--roboto-regular);
  .MuiDialog-paperScrollPaper {
    // width: calc(100vw - 60%);
    width: 570px;
    border-radius: 16px;
    box-shadow: 2px 4px 4px 0 rgba(166, 174, 182, 0.2);
    scrollbar-width: none;
  }
  .print-close {
    background: url(/images/close-blue.png) no-repeat;
    width: 12px;
    height: 12px;
    cursor: pointer;
    background-size: contain;
  }

  .layout-alert-msg {
    color: #ff3333;
    font-size: 12px;
    position: relative;
    padding-left: 15px;
    text-transform: initial;
  }
}

//  React Calendar

.date-filter-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  .date-filter {
    display: flex;
    .MuiSvgIcon-root {
      margin-right: 5px;
    }
    .MuiFormControl-marginNormal {
      margin-top: 0;
    }
  }
  .date-modified {
    background: url('/images/MODIFIED.svg') no-repeat;
    background-position: center;
    width: 40px;
    height: 40px;
  }
  .date-created {
    background: url('/images/CREATED.svg') no-repeat;
    background-position: center;
    width: 40px;
    height: 40px;
  }
  .date-due {
    background: url('/images/DUE.svg') no-repeat;
    background-position: center;
    width: 40px;
    height: 40px;
  }
  .filter-datepicker {
    .MuiInputBase-input {
      color: var(--ed-blue);
    }
  }
}
.date-filter-main {
  .date-container {
    color: var(--ed-blue);
    font: 8px var(--roboto-regular) !important;
    margin-top: 5px;
    margin-left: 5px;
  }
  .LASTMODIFIEDDATE {
    background: url('/images/MODIFIED_small.svg') no-repeat;
    background-position: center;
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
  .CREATIONDATE {
    background: url('/images/CREATED_blue.svg') no-repeat;
    background-position: center;
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
  .DUEDATE {
    background: url('/images/DUE_small.svg') no-repeat;
    background-position: center;
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
}

.filter-datepicker {
  flex: 0 1 48%;
  font-family: var(--roboto-regular);
  &--container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    input {
      &::-webkit-input-placeholder {
        font: var(--size-xs) var(--roboto-regular) !important;
        color: var(--new) !important;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        font: var(--size-xs) var(--roboto-regular) !important;
        color: var(--new) !important;
        opacity: 1;
      }

      &::placeholder {
        font: var(--size-xs) var(--roboto-regular) !important;
        color: var(--new) !important;
        opacity: 1;
      }
    }
    .MuiInputAdornment-positionEnd {
      margin-right: 5px;
    }
  }
  .MuiInputBase-root {
    background: var(--white);
    color: var(--secondary);
    &.Mui-disabled:before {
      border: 0 !important;
    }
  }
  .MuiButtonBase-root {
    padding: 5px;
  }

  .MuiInput-underline {
    &:before,
    &:after {
      border: none;
    }
    &:hover {
      &:before,
      &:after {
        border: none !important;
      }
    }
  }
  .MuiInputBase-input {
    padding: 6px 5px 6px;
    &::-webkit-input-placeholder {
      font: var(--size-xs) var(--roboto-regular) !important;
      color: var(--new) !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      font: var(--size-xs) var(--roboto-regular) !important;
      color: var(--new) !important;
      opacity: 1;
    }

    &::placeholder {
      font: var(--size-xs) var(--roboto-regular) !important;
      color: var(--new) !important;
      opacity: 1;
    }
  }
}

.filter-datepicker-new-project {
  flex: 0 1 48%;
  font-family: var(--roboto-regular);
  &--container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    &::-webkit-input-placeholder {
      font: var(--size-xs) var(--roboto-regular) !important;
      color: var(--new) !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      font: var(--size-xs) var(--roboto-regular) !important;
      color: var(--new) !important;
      opacity: 1;
    }

    &::placeholder {
      font: var(--size-xs) var(--roboto-regular) !important;
      color: var(--new) !important;
      opacity: 1;
    }
  }
  .MuiInputAdornment-positionEnd {
    margin-right: 5px;
  }
  .MuiInputBase-root {
    background: var(--white);
    color: var(--secondary);
  }
  .MuiButtonBase-root {
    padding: 5px;
  }
  .MuiInputBase-input {
    padding: 6px 5px 6px;
    &::-webkit-input-placeholder {
      font: var(--size-xs) var(--roboto-regular) !important;
      color: var(--new) !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      font: var(--size-xs) var(--roboto-regular) !important;
      color: var(--new) !important;
      opacity: 1;
    }

    &::placeholder {
      font: var(--size-xs) var(--roboto-regular) !important;
      color: var(--new) !important;
      opacity: 1;
    }
  }
}

//  React Calendar
.date-container {
  position: relative;
}
.calendar-wrapper,
.ed-cal {
  min-width: 155px;
  // width: 200px;
  .react-calendar__month-view__weekdays {
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 9px;
    color: #9e9e9e;
  }

  .react-calendar {
    padding-bottom: 20px;
    left: 0;
    width: 310px !important;
    max-width: 100%;
    min-width: 310px;
    background: white;
    border: 1px solid white;
    font: 10px var(--roboto-regular);
    color: black;
    border-radius: 7px;
    abbr[data-original-title],
    abbr[title] {
      border: none;
      text-decoration: none;
      cursor: pointer;
    }
    &__tile {
      &:disabled {
        border-radius: 0;
        background: transparent;
        color: #9e9e9e !important;
      }
      &:enabled:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
        color: black;
      }
      &--now {
        &:hover,
        &:enabled:focus {
          background: rgba(0, 0, 0, 0.04) !important;
        }
      }
    }
    &__decade-view,
    &__year-view__months,
    &__century-view__decades__decade {
      .react-calendar__tile {
        border-radius: 0;
      }
    }
    &__navigation {
      &__label {
        &:enabled:hover {
          background: transparent !important;
        }
      }
      button {
        color: #2c3d50;
        font: var(--size-s) var(--roboto-regular);
        &:disabled {
          background: transparent;
          color: rgba(0, 0, 0, 0.26) !important;
        }
        &:enabled:hover,
        &:enabled:focus {
          background: transparent;
        }
      }
      &__arrow {
        &:enabled:hover,
        &:enabled:focus {
          background: rgba(0, 0, 0, 0.04) !important;
          border-radius: 50%;
        }
      }
    }
  }
  .react-calendar__month-view__days__day--weekend {
    color: black !important;
  }
  .react-calendar__tile--active {
    background: #1382c5 !important;
    color: white;
    border-radius: 50%;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    border-radius: 50%;
  }
  .react-calendar__month-view__weekNumbers {
    color: #a0a096;
  }
  .box-calendar {
    // margin-bottom: 10px;
    background: var(--white);
    color: #8a9cad;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e3eaf0;
    background-color: #f9fbfc;
    width: 200px;
    &:hover {
      background-color: #f0f2f2;
    }
    > p {
      padding: 5px 10px;
      margin: 0;
      font-size: 14px;
    }
    &.copied-ticket {
      p {
        background-color: #dbecf7 !important;
      }
    }
    > div {
      border-left: 1px solid #e3eaf0;
      display: flex;
      align-items: center;
      padding: 10px 5px 10px 10px;
      cursor: pointer;
    }
    .labelDate {
      font: var(--size-xs) var(--roboto-regular);
      padding-left: 5px;

      p {
        margin: 0;
      }
    }
    .calendar-icon {
      background-image: url(./images/calendar-active-icon.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      width: 18px;
      height: 18px;
      padding: 12px;

      &:hover {
        background-image: url(./images/calendar-hover-icon.svg);
        background-color: #d3ebff;
        border-radius: 50%;
      }
    }
  }

  .box-calendar_right {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    > div {
      border-left: none;
    }
  }
  &_right {
    .react-calendar {
      top: 0px;
      bottom: auto;
    }
  }
}
.not-allowed {
  cursor: not-allowed;
}

/*Left menu*/

.left-menu {
  position: absolute;
  top: 0;
  left: 0;
  transition: left 0.5s;
  width: 100%;
  background: var(--primary);
  white-space: nowrap;
  &__container {
    background: var(--primary);
    color: var(--white);
    height: 100vh;
    overflow-y: auto;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 20px;
    opacity: 1;
    overflow-x: hidden;
    input.add {
      border: 0;
      border-bottom: none;
      background-repeat: no-repeat;
      background-size: 13px 13px;
      background-color: transparent;
      font: var(--size-xs) var(--roboto-regular);
      color: var(--white);
      width: 100%;
      &:focus {
        box-shadow: none;
        outline: none;
        background-color: #184360;
        transition: 0.3s;
        width: 100%;
      }
      &::-ms-clear {
        display: none;
      }
    }
    // Add input
    input.add ~ .focus-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: #184360;
      transition: 0.3s;
      z-index: -1;
    }
    input.add:focus ~ .focus-bg,
    input.add:hover ~ .focus-bg {
      transition: 0.3s;
      width: 100%;
    }
    input.add::-webkit-input-placeholder {
      font-size: var(--size-s);
      color: var(--white) !important;
    }
  }
  &__project-container {
    align-items: flex-end;
    border-bottom: 1px solid var(--border);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 5px;
    margin-bottom: 10%;
    img {
      cursor: pointer;
      height: 20px;
      margin-bottom: 3px;
      margin-right: 5px;
    }
  }
  &__project-dropdown {
    min-width: 260px;
    position: relative;
    padding-right: 5px;
    padding-left: 5px;

    .dropdown-container {
      position: relative;
      p {
        margin-bottom: 0;
        font: var(--size-xs) var(--roboto-regular);
      }
      > span {
        font: var(--size-base) var(--roboto-medium);
        display: block;
        overflow-x: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        padding-right: 20px;
        transition: 2s;
        &::after {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          background: url(/images/down_arrow.svg) no-repeat;
          filter: brightness(0) invert(1); // Fixme: Remove when icon is provided
          background-position: bottom;
          opacity: 0.2;
          position: absolute;
          right: -2px;
          bottom: 3px;
          padding-right: 25px;
        }
        &.rotateArrow {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
    .project-dropdown {
      width: 100%;
      position: absolute;
      background: var(--white);
      color: var(--primary);
      z-index: 1;
      .contract-detail {
        padding: 5px 5px 0;
        color: var(--ed-blue);
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
      .search-filter {
        padding: 0 5px;
        input.search {
          padding-left: 20px;
          height: 30px;
          border: 0;
          border-bottom: 1px solid var(--border);
          background-image: url(/images/search.png);
          background-repeat: no-repeat;
          background-size: 13px 13px;
          background-position: 2px 7px;
          font: var(--size-xs) var(--roboto-regular);
          color: var(--primary);
          width: 100%;
          &:focus {
            box-shadow: none;
            outline: none;
          }
          &::-ms-clear {
            display: none;
          }
        }
        input:focus::-webkit-input-placeholder {
          color: transparent !important;
          font-size: var(--size-xl);
        }
        input:focus::-moz-placeholder {
          color: transparent !important;
        }
        input:focus:-ms-input-placeholder {
          color: transparent !important;
        }
        input:focus:-moz-placeholder {
          color: transparent !important;
        }
        input::-webkit-input-placeholder {
          font-size: var(--size-xs);
          color: #cdcdcd !important;
        }
        input.add {
          border: 0;
          border-bottom: none;
          background-repeat: no-repeat;
          background-size: 13px 13px;
          background-color: transparent;
          font: var(--size-xs) var(--roboto-regular);
          color: var(--white);
          width: 100%;
          &:focus {
            box-shadow: none;
            outline: none;
            background-color: #184360;
            transition: 0.3s;
            width: 100%;
          }
          &::-ms-clear {
            display: none;
          }
        }
        // Add input
        input.add ~ .focus-bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 100%;
          background-color: #184360;
          transition: 0.3s;
          z-index: -1;
        }
        input.add:focus ~ .focus-bg,
        input.add:hover ~ .focus-bg {
          transition: 0.3s;
          width: 100%;
        }
        input.add::-webkit-input-placeholder {
          font-size: var(--size-s);
          color: var(--white) !important;
        }
      }
      .project-list {
        max-height: 130px;
        overflow-y: auto;
        ul {
          list-style: none;
          padding-left: 0;
          li {
            padding: 5px;
            margin: 2px 0;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.select-all-project {
              color: #cdcdcd;
              font-size: var(--size-xs);
            }
            &.active-project {
              background: #f8f8f8;
            }
            &:hover {
              background: var(--ed-blue);
              color: var(--white);
              .MuiCheckbox-colorPrimary {
                color: var(--white) !important;
              }
            }
            &.selected {
              pointer-events: none;
              background-color: #f8f8f8;
            }
          }
        }
        &__container {
          display: flex;
          flex-direction: row;
          align-items: center;
          > span {
            padding: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          svg {
            font-size: var(--size-l);
          }
        }
      }
      .apply-project {
        display: flex;
        justify-content: center;
        > .btn {
          margin: 5px;
          padding: 10px 45px;
        }
      }
    }
  }
  &__collapsed-panel {
    position: absolute;
    top: 60px;
    right: -17px;
    background: var(--primary);
    z-index: 100;
    text-align: center;
    display: none;
    img {
      width: 15px;
      height: 15px;
    }
    > div {
      display: none;
      cursor: pointer;
      margin-bottom: 10px;
      padding: 15px 2px;
      position: relative;
      transition: 0.3s;
      &:first-child {
        display: flex;
        margin-bottom: 0;
        border: 1px solid white;
        width: 35px;
        height: 35px;
        justify-content: center;
        align-items: center;
        .arrow-collapse {
          width: 15px;
          height: 15px;
          background: url('/images/left-white.svg') no-repeat center;
          background-size: contain;
        }
        &:hover {
          background: var(--white);
          border: 1px solid #2a3a4c;
          .arrow-collapse {
            background: url('/images/left-color.svg') no-repeat center;
            background-size: contain;
          }
        }
      }
      .filterBadge {
        width: 10px;
        background: var(--ed-blue);
        height: 10px;
        z-index: 1;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 7px;
      }
    }
    .toggle-icon {
      svg {
        fill: #a6b2bc;
        font-size: 26px;
        transform: rotate(90deg);
      }
    }
  }
  &.collapsed {
    .left-menu {
      &__collapsed-panel {
        display: block;
        > div {
          &:first-child {
            .arrow-collapse {
              width: 15px;
              height: 15px;
              background: url('/images/right-white.svg') no-repeat center;
              background-size: contain;
            }
            &:hover {
              background: var(--white);
              border: 1px solid #2a3a4c;
              .arrow-collapse {
                background: url('/images/right-color.svg') no-repeat center;
                background-size: contain;
              }
            }
          }
        }
        &.map-filter {
          > div {
            &:first-child {
              padding: 10px 2px;
              border: 0;
              .arrow-collapse {
                float: right;
                margin-right: 6px;
              }
              &:hover {
                background: none;
                border: 0;
                .arrow-collapse {
                  background: url('/images/right-white.svg') no-repeat center;
                  background-size: contain;
                }
              }
            }
            padding: 0 2px;
            display: block;
          }
        }
      }
      &__container {
        padding: 0;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  &:hover {
    .left-menu__collapsed-panel {
      display: block;
    }
  }
}

/*Project Left menu */
.projects {
  &--container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
    .split-pane-left {
      width: 344px;
    }
  }
  &--left-block {
    position: fixed;
    top: 60px;
    left: 0;
    padding: 0;
    width: inherit;
    z-index: 1;
  }
  &--right-block {
    margin-left: auto;
    transition: width 0.5s;
    margin-top: -25px;
  }
  .hide-sidebar {
    .projects--right-block {
      width: 96%;
    }
  }
  .separator-col {
    z-index: 0;
  }
}

.filter-accordian {
  color: var(--white) !important;
  background: transparent !important;
  box-shadow: none !important;
  &:before {
    content: unset !important;
  }
  &.Mui-expanded {
    margin: 0 !important;
  }
  .MuiAccordionSummary-root {
    min-height: unset !important;
    height: 32px;
    padding: 0;
    &:hover {
      background: rgba(19, 129, 196, 0.15);
    }
  }
  .MuiAccordionSummary-root {
    &.Mui-expanded {
      border-bottom: 1px solid rgba(227, 234, 240, 0.5);
    }
  }
  .MuiAccordionSummary-content {
    align-items: center;
    width: 100%;
    height: 100%;
    img {
      margin-right: 5px;
      &.drawing-tile {
        margin-right: 5px;
        margin-left: 5px;
      }
      &.audit-tile {
        margin-right: 12px;
        margin-left: 5px;
      }
    }
    svg {
      margin: 0 7px;
      font-size: var(--size-base);
      &.filled {
        fill: var(--ed-blue);
      }
      fill: var(--primary);
    }
    .dummy-space {
      min-width: 30px;
    }
    span {
      font: 16px var(--roboto-medium);
    }
  }
  .MuiAccordionDetails-root {
    flex-direction: column;
    padding: 0 0 0px 0;
    .dummy-space {
      min-width: 30px;
    }
  }
  .filter-inner-accordian {
    color: var(--white);
    background: var(--primary) !important;
    box-shadow: none;
    margin: 0 !important;
    &::before {
      content: none;
    }
    .header-accordian {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid var(--opacity-border);
      height: 100%;
      svg {
        margin-left: 0;
      }
    }
    .body-accordian {
      display: flex;
      align-items: flex-end;
      width: 90%;
      border-bottom: 1px solid var(--opacity-border);
      height: 32px;
      flex: 1;
      > div {
        display: flex;
        align-items: center;
        height: 26px;
        width: 100%;
        span {
          margin-left: 5px;
        }
      }
    }
    img {
      margin-right: 10px;
      margin-left: 10px;
      object-fit: contain;
      min-width: 10px;
    }
    .MuiAccordionSummary-root {
      border-bottom: 0 !important;
    }
    &.active-filter {
      border-bottom: 1px solid var(--opacity-border);
      .body-accordian {
        > div {
          background: var(--ed-blue) !important;
          border-radius: 5px 0 0;
          .filled {
            fill: var(--ed-dark_blue);
          }
        }
      }
      .filter-inner-accordian {
        // Audit filter for ticket 3rd level
        .body-accordian {
          > div {
            background: none !important;
            border: 0;
            .filled {
              fill: var(--ed-blue);
            }
          }
        }
      }
    }
    .MuiAccordionSummary-content {
      span {
        font: var(--size-s) var(--roboto-regular);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .caret-dropdown {
      margin-top: 9px;
    }
  }
}

.filter-accordian__proj {
  @extend .filter-accordian;
  border-bottom: none;
  .MuiAccordionSummary-root {
    &.Mui-expanded {
      margin-top: 15px;
      border-bottom: none;
    }
  }
}
.white-svg {
  fill: var(--white) !important;
}
.flex-right {
  margin-left: auto !important;
  margin-right: 10px !important;
  font-size: var(--size-xl) !important;
}
.clear-filter {
  display: flex;
  color: var(--ed-blue);
  cursor: pointer;
  font: var(--size-s) var(--roboto-medium);
  margin: 0 50px 0 10px;
  text-align: right;
  min-height: 15px;
  position: fixed;
  right: 0;

  &.removedFixed {
    @extend .clear-filter;
    position: absolute;
    right: 0;
  }

  &:hover {
    opacity: 0.5;
  }
  span {
    margin-right: 5px;
  }
}
.count-container {
  font: var(--size-xxs) var(--roboto-medium) !important;
  opacity: 0.35;
  overflow: visible !important;
  margin-left: 5px;
}
.filter-selected {
  font: var(--size-xxs) var(--roboto-medium) !important;
  color: var(--ed-blue);
  margin-left: 5px;
  opacity: 1;
  overflow: visible !important;
  text-transform: lowercase;
}
.scroll-to-top {
  > div {
    cursor: pointer;
    z-index: 1000;
    display: flex;
    align-items: center;
    span {
      color: var(--ed-blue);
      font: var(--size-s) var(--roboto-regular);
    }
  }
  .scroll-to-top-icon {
    border-radius: 50px;
    fill: var(--white);
    background: var(--ed-blue);
    font-size: 3em;
    width: 40px !important;
    margin-right: 10px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.expandKey {
  background: rgba(256, 256, 256, 0.28);
  padding: 3px 6px;
  margin-left: 5px;
}
.va-middle {
  vertical-align: middle;
}

.split-pane-col {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.split-pane-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.split-pane-top {
  flex: 1;
  background-color: #ccc;
  text-align: left;
  padding: 2%;
}
.split-pane-bottom {
  flex: 1;
  background-color: #ccc;
  text-align: left;
  padding: 2%;
}
.split-pane-right {
  flex: 1;
  overflow-y: auto;
}
.split-pane-left {
  flex: 1;
  position: relative;
  height: 100vh;
  top: -13px;
  max-width: 340px;
  width: 340px;
  min-width: 340px;
  margin-top: 10px;
  // z-index: 1;
}
.separator-col {
  border: 2px solid var(--primary);
  cursor: col-resize;
  min-height: 100vh;
  position: relative;
  top: -13px;
  height: calc(100% + 13px);
}
.separator-row {
  border: 2px solid var(--primary);
  cursor: row-resize;
}

.custom-scrollbar {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    background-color: transparent;
    position: absolute;
    width: 3px;
    opacity: 0;
  }
}
.custom-scrollbar:hover {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 3px;
    opacity: 1;
  }
  &::-webkit-scrollbar-thumb {
    background: #d5d5d5;
  }
}
@media (hover: none) {
  .custom-scrollbar {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: thin;
      opacity: 1;
    }
    &::-webkit-scrollbar-thumb {
      background: #d5d5d5;
    }
  }
}

.projectname-tooltip {
  width: auto;
}
.tootip_proj_name {
  font: var(--size-base) var(--roboto-medium);
  line-height: 23px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
}
.fixed-left-panel {
  position: fixed !important;
  top: 55px !important;
  z-index: 100;
}

.loader-lazy {
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 1500;
}

.ed-chip {
  align-items: center;
  background: rgba(0, 0, 0, 0) !important;
  border: 1px solid rgba(44, 61, 80, 0.2) !important;
  color: #2c3d50 !important;
  display: -ms-flexbox;
  display: flex;
  height: auto !important;
  justify-content: center;
  min-height: 24px;
  padding: 5px;
  border-radius: 15px !important;
  margin: 8px 5px 0 0;
  .MuiChip-deleteIcon {
    fill: #2c3d50;
  }
  .MuiChip-labelSmall {
    font: var(--size-base) var(--roboto-regular);
    white-space: pre-line;
    word-break: break-all;
  }
}

.bulk-edit {
  min-width: 380px;
  background: var(--ed-dark_blue);
  color: var(--white);
  &__container {
    padding: 45px;
    padding-bottom: 25px;
  }
  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--white);
    padding-bottom: 10px;
    p {
      font: var(--size-s) var(--roboto-medium);
      margin-left: 10px;
    }
  }
  &__body {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: var(--size-s) var(--roboto-regular);
    p {
      text-align: center;
      &.confirm-edit-hint {
        margin-top: 40px;
        margin-bottom: 25px;
        font: var(--size-base) var(--roboto-medium);
      }
    }
    input {
      height: 30px;
      color: var(--primary);
      font: var(--size-s) var(--roboto-regular);
      font-style: italic;
      &:focus {
        box-shadow: none;
        outline: none;
      }
      &::-webkit-input-placeholder {
        color: var(--ed-grey);
        font: var(--size-s) var(--roboto-regular);
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--ed-grey);
        font: var(--size-s) var(--roboto-regular);
      }

      &::placeholder {
        color: var(--ed-grey);
        font: var(--size-s) var(--roboto-regular);
      }
    }
  }
  &__actions {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn--cancelBulk {
      margin-right: 20px;
    }
  }
  &__percentage {
    margin-top: 60px;
    margin-bottom: 25px;
    .max-time-info {
      text-align: right;
      margin-top: 5px;
    }
  }
}

.select-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .display-selected-tickets {
    align-items: center;
    color: var(--ed-blue);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font: var(--size-s) var(--roboto-regular);
    height: 31px;
    padding: 10px;
    margin-right: 10px;

    .display-selected-tickets-value {
      margin-right: 5px;
    }
  }

  .display-selected-tickets-close {
    background-image: url(/images/close.svg);
    background-repeat: no-repeat;
    width: 17px;
    border: 1px solid var(--white);
    border-radius: 8px;
    padding: 7px;
    margin-left: 10px;
    background-color: var(--white);
    cursor: pointer;
    background-position: center;
    display: none;
  }
  &--arrow {
    height: 12px;
    margin-left: 15px;
  }
  &__action {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    &--bulkTags {
      background: url(/images/tag-new-icon.svg);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
      border-radius: 50%;
      &--active {
        background: var(--ed-dark_blue);
        color: var(--white);
      }
      &:hover {
        background: url(/images/tag-new-icon.svg);
        background-color: #1382c526;
        background-repeat: no-repeat;
        background-position: center;
        color: var(--white);
      }
    }
    &--tags {
      background: url(/images/tag-new-icon.svg) var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    &--drawing {
      background: url(/images/drawing-new-icon.svg) var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    &--move {
      background: url(/images/TAG_icon.svg) var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    &--bulkArchieve {
      background: url(/images/archive-new-icon.svg);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
      border-radius: 50%;
      &:hover {
        background-color: #1382c526;
      }
      &.bulkDearchive_icon {
        background: url(/images/de_archive.svg);
      }
    }
    &--archieve {
      background: url(/images/archive-new-icon.svg) var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
      &.dearchive_icon {
        background: url(/images/de_archive.svg);
      }
    }
    &--bulkDownload {
      background: url(/images/download-new-icon.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-color: none;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
      border-radius: 50%;
      &:hover {
        background-color: #1382c526;
      }
      &--active {
        background: var(--ed-dark_blue);
        color: var(--white);
      }
    }
    &--download {
      background: url(/images/download-new-icon.svg) var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    &--geo {
      background: url('/images/geo-new-icon.svg') var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 35px;
      width: 35px;
      margin-left: 10px;
      cursor: pointer;
    }
    &--restore {
      background: url(/images/RESTORE_icon.svg) var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    &--refresh {
      background: url(/images/update-new-icon.svg) var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    &--delete {
      background: url(/images/BIN_icon.svg) var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    &--upload-convert {
      background: url(/images/newandconvert.svg) var(--slect-filter-bckgrnd);
      background-position: center;
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    &--dropdown {
      .more-options {
        background: url(/images/more.svg) var(--slect-filter-bckgrnd);
        background-position: center;
        background-repeat: no-repeat;
        height: 30px;
        width: 30px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    &--dropdown:hover {
      .dropdown-content {
        display: block;
      }
    }
  }
}

.remove_date_filter {
  padding-left: 10px;
}
hr {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 0;
  border-top: 1px solid #eee;
}

/*Tooltip*/

.MuiTooltip-popper {
  top: 5px !important;
  .MuiTooltip-tooltipPlacementTop {
    margin: 8px 0;
    .MuiTooltip-arrow {
      color: var(--secondary);
    }
  }
  .MuiTooltip-tooltipPlacementBottom {
    margin: 1px 0;
  }
  .MuiTooltip-tooltipPlacementRight {
    margin: -16px 14px;
    padding: 8px;
  }
  &.MuiTooltip-popperArrow[x-placement*='right'] {
    .MuiTooltip-arrow {
      width: 1.5em;
      height: 2em;
      margin-top: 8px;
      margin-left: -1.4em;
      margin-bottom: 4px;
    }
  }
}

label.td-file-wrapper {
  border: 1px solid var(--slect-filter-border);
  border-radius: 5px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 100;
  padding: 1px 4px;
  position: relative;
  text-align: center;
}

.mt-20 {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  img {
    margin-right: 5px;
    width: 16px;
  }
}

.m-0 {
  margin: 0;
}
.fsp-summary__filter {
  margin-left: 0 !important;
}
.mt-30 {
  margin-top: 30px;
}
.attachmentTip {
  display: flex;
  margin-bottom: 10px;

  img {
    cursor: pointer;
  }
  .detailTipBold {
    text-align: center;
    font: var(--size-base) var(--roboto-bold);
    font-style: italic;
    color: var(--ed-dark_blue);
    margin-bottom: 0;
    margin-top: 4px;
    margin-left: 20px;
  }
  .detailTip {
    text-align: center;
    font: var(--size-base) var(--roboto-regular);
    font-style: italic;
    color: var(--ed-dark_blue);
    margin-bottom: 0;
    margin-top: 4px;
    margin-left: 5px;
  }
}

/* Project|Contract Timeline style*/

.projectTimeline {
  &__container {
    margin-top: 75px;
  }
  &__containers {
    margin-top: 25px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid #e2e2e2;
    &__left {
      span {
        padding-left: 10px;
        font: var(--size-s) var(--roboto-regular);
        color: var(--ed-dark_blue);
      }
    }
    &__right {
      cursor: pointer;
      span {
        padding-right: 10px;
        font: var(--size-xs) var(--roboto-regular);
        color: var(--ed-dark_blue);
      }
      img {
        transform: rotate(-90deg);
        height: 8px;
        width: 13px;
      }
    }
  }
  &__body {
    padding: 40px;
    padding-bottom: 45px;
    &.no-pad {
      padding: 0;
    }
  }
  &__item {
    border-top: 1px solid #e2e2e2;
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: var(--ed-dark_blue);
    }
    &__left {
      font: var(--size-s) var(--roboto-regular);
    }
    &__right {
      font: var(--size-xs) var(--roboto-regular);
    }
    &__content {
      padding-top: 5px;
      font: var(--size-s) var(--roboto-regular);
      color: #787878;
    }
  }
}
.hide-table-content {
  display: none;
}

.license-charges-container {
  width: 700px;
  padding: 30px;
  color: var(--group-header);

  .red-info {
    position: absolute;
  }
  .license-charges-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 35px;
    font: var(--size-xl) var(--roboto-regular);

    .close-popup {
      width: 23px;
      cursor: pointer;
      margin-bottom: 8px;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .license-info {
    display: flex;
    padding: 10px;
    border-radius: 8px;
    background-color: rgba(211, 235, 255, 0.2);
    .info-icon {
      margin: 0 10px;
    }
    p {
      margin: 0 !important;
      line-height: 24px;
    }
  }
  .license-charges-body {
    font: var(--size-base) var(--roboto-regular);
    margin-top: 30px;
    p:first-child {
      line-height: 25px;
    }
    p:nth-child(2) {
      margin-top: 25px;
    }
  }
  .license-charges-footer {
    display: flex;
    float: right;
    margin: 30px 0 0px 0;
  }
  .btn--cancel3 {
    font: var(--size-base) var(--roboto-regular);
  }
}

.icon-bg {
  background-color: var(--group-header);
  padding: 6px 10px;
  border-radius: 50%;
}
.icon-nbg {
  background-color: #e5ecf1;
  padding: 6px 10px;
  border-radius: 50%;
}

.license-charges-body-info {
  margin-top: 35px;
  font: var(--size-base) var(--roboto-regular);
  color: #233b4f;
  line-height: 1.5;
}
.replace-acc-email {
  display: flex;
  flex: 0.38;

  .current-acc-container {
    flex: 0.98;
    margin-top: 18px;
    font: var(--size-s) var(--roboto-regular);
    .current-acc {
      color: #8d9cab;
      margin-bottom: 7px;
    }
  }
}

.license-charges-container {
  .active-icon {
    display: none;
  }

  .inactive-icon {
    display: none;
  }
}
.license-acc-emailinput-container {
  background-color: #f9fbfc;
  margin-top: 12px;
  width: 330px;
  border-bottom: 1px solid #e1e1e180;
  padding: 0 10px;

  .autocomplete-input input {
    border-bottom: none;
  }

  .MuiInputBase-root {
    padding: 0 10px;
  }

  .label-class {
    margin-top: 4px;
  }
}

.license-charges-body-acc {
  margin-top: 35px;

  .autocomplete-input input {
    border-bottom: none;
  }

  .license-emailinput-container {
    background-color: #f9fbfc;
    margin-top: 12px;
    padding: 0 10px;
    border-bottom: 1px solid #e1e1e180;
    position: relative;
    .label-class {
      padding-top: 15px;
      color: #a6aeb6;
    }

    .custom-chip {
      background-color: #f9fbfc !important;
    }
  }
}
.mr-15 {
  margin-right: 15px !important;
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
  text-align: center;
  font-style: var(--roboto-regular) !important;
  color: #2c3d50;
  &.manage-tag-no-results {
    height: auto !important;
    margin-top: 80px;
  }
  .no-results-found {
    font-weight: bold;
    color: var(--primary);
    font-size: 18px;
  }
  .noResultIcon {
    margin-top: 10px;
    height: auto;
  }
  .search-suggestion {
    margin-top: 15px;
    font-size: 16px;
    color: var(--primary) !important;
  }
  .or {
    font-size: 16px;
  }
  .schedule-date-header,
  .schedule-date {
    font-weight: bold;
    color: var(--primary);
    font-size: 16px;
    margin-top: 10px;
  }
  .schedule-date {
    margin-top: 0;
  }
  .arch-suggestion {
    font-size: var(--size-base);
    color: var(--ed-blue);
    cursor: pointer;
  }

  .start-container {
    margin-top: 10px;
    .start-btn {
      font: var(--size-base) var(--roboto-medium);
      width: 126px;
      height: 35px;
      padding: 1rem 3rem;
      border-radius: 1rem;
      text-transform: none;
      background-color: #1382c5;
      text-wrap: nowrap;
    }
  }

  .start-date-bill-info {
    margin-top: 15px;
    padding: 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    img {
      margin-right: 4px;
    }

    span {
      font: var(--size-s) var(--roboto-regular);
      color: #a6aeb6;
    }
  }
}
.proj-no-results {
  @extend .no-results;
  height: calc(100vh - 180px);
}
.email-chip-border {
  cursor: pointer;
  margin: 5px;
  border-radius: 4px;
  border: solid 1px #e5ecf1;
  padding: 4px 8px !important;
  &:hover {
    background: #f0f2f2;
  }
  .MuiTooltip-popper {
    top: 0 !important;
  }
}

/* Search Container design */
.search_container {
  display: flex;
  align-items: center;
  // margin-right: 15px;
  position: relative;
  transition: width 0.3s ease;

  .close-icon {
    position: relative;
    left: -20px;
    top: 8px;
    transform: translateY(-50%);
    fill: #cecece !important;
    cursor: pointer;
  }

  input.search {
    padding-left: 30px;
    height: 30px;
    border: 1px solid #e5ecf1;
    border-radius: 2px;
    background: url(/images/search-filter-icon.svg) no-repeat;
    background-size: 20px;
    background-position: 7px 4px;
    background-color: transparent;
    font: var(--size-s) var(--roboto-regular);
    color: var(--group-header);
    width: 100%;
    padding-right: 35px;
    box-sizing: border-box;

    &::placeholder {
      font: var(--size-s) var(--roboto-regular);
      color: var(--group-header);
      overflow: hidden;
      text-overflow: ellipsis !important;
      white-space: nowrap;
    }

    &:focus {
      outline: none;
      border-color: #e5ecf1;
    }
  }

  input.add::-webkit-input-placeholder {
    font-size: var(--size-s);
    color: var(--white) !important;
  }

  input.add ~ .focus-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #184360;
    transition: 0.3s;
    z-index: -1;
  }

  input.add:focus ~ .focus-bg,
  input.add:hover ~ .focus-bg {
    width: 100%;
  }
}

/* End of search container design */
.admin-list {
  padding: 30px;
  min-width: 500px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: var(--size-xl) var(--roboto-regular);
    color: var(--group-header);
    img {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  &__body {
    margin: 20px 0;
    p {
      font: var(--size-base) var(--roboto-regular);
      color: var(--group-header);
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .btn {
      width: auto;
    }
  }
}

.due-date-default {
  color: #2c3c50;
  background-color: transparent;
  font-size: var(--size-xs);

  img {
    margin: 0 3px 3px 0;
  }
}

.due-date-overdue {
  color: #f43636;
  background-color: #ffe8e8;
  padding: 1px 2px;
  border-radius: 4px;
  font-size: var(--size-xs);

  img {
    margin: 0 3px 3px 0;
  }
}
